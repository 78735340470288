// 预约面试的接口定义
import service from '@/config/http'

// 获取候选人基础信息
export const getReserveCandidateInfo = data => {
  const req = {
    url: '/candidate/interviewPlan/reserveCandidate/getReserveCandidateInfo',
    data,
    method: 'post',
  }
  return service(req)
}

export const createReserve = data => {
  const req = {
    url: '/candidate/interviewPlan/reserveCandidate/createReserve',
    data,
    method: 'post',
  }
  return service(req)
}

export const getReserveInfoByJobseeker = data => {
  const req = {
    url: '/candidate/interviewPlan/reserveCandidate/getReserveInfoByJobseeker',
    data,
    method: 'post',
  }
  return service(req)
}

export const updateReserveInfo = data => {
  const req = {
    url: '/candidate/interviewPlan/reserveCandidate/updateReserveInfo',
    data,
    method: 'post',
  }
  return service(req)
}

export const getCommentFormInfo = data => {
  const req = {
    url: '/cand/comment/getCommentFormInfo',
    data,
    method: 'post',
  }
  return service(req)
}

export const saveCommentReplyContent = data => {
  const req = {
    url: '/cand/comment/saveCommentReplyContent',
    data,
    method: 'post',
  }
  return service(req)
}
