<template>
  <div class="confirm-reserve">
    <div class="header">
      <div class="title">确认面试信息</div>
      <div class="subtitle">请确认您选择的面试信息</div>
    </div>
    <div class="body">
      <div class="card">
        <div class="title">面试信息</div>
        <div class="form">
          <div class="form-row">
            <div class="form-label">公司名称：</div>
            <div class="form-content">{{ info.companyName }}</div>
          </div>
          <div class="form-row">
            <div class="form-label">面试职位：</div>
            <div class="form-content">{{ info.positionName }}</div>
          </div>
          <div class="form-row">
            <div class="form-label">面试时间：</div>
            <div class="form-content">{{ info.date + ' ' + info.time }}</div>
          </div>
          <div class="form-row">
            <div class="form-label">候选人姓名：</div>
            <div class="form-content">{{ info.candidateName }}</div>
          </div>
          <div class="form-row">
            <div class="form-label">面试形式：</div>
            <div class="form-content">{{ getInterviewType(info.interviewMode) }}</div>
          </div>
          <div v-if="info.interviewMode == interviewMode.offlineInterview" class="form-row">
            <div class="form-label">面试地址：</div>
            <div class="form-content">{{ info.interviewAddr }}</div>
          </div>
        </div>
      </div>
      <div class="action">
        <div class="submit" @click="submit"><span>确认预约</span></div>
        <div class="cancel" @click="back"><span>重新选择</span></div>
      </div>
    </div>
  </div>
</template>
<script>
import { createReserve, updateReserveInfo } from '../../api/interview'
export default {
  name: 'ConfirmReserve',
  data: () => {
    return {
      info: {
        id: '',
        jobseekerChannelId: '',
        reserveId: '',
        candidateName: '',
        companyName: '',
        position: '',
        interviewAddr: '',
        date: '',
        time: '',
        groupInfoId: '',
      },
      interviewMode: {
        offlineInterview: 0,
        onlineInterview: 1,
        tencentInterview: 3,
      },
      modleData: [
        {
          value: 0,
          name: '线下面试',
        },
        {
          value: 1,
          name: '线上面试',
        },
        {
          value: 3,
          name: '腾讯面试',
        },
        {
          value: 4,
          name: '视频面试',
        },
      ],
    }
  },
  created() {
    // 获取url参数数据
    this.info = this.$route.query
  },
  methods: {
    submit() {
      if (this.info.id) {
        // 更新
        updateReserveInfo(this.info).then(res => {
          if (res.code === 0) {
            this.$router.push({
              path: '/schedule-an-interview/success-reserve',
              query: {
                reserveId: this.info.reserveId,
                jobseekerChannelId: this.info.jobseekerChannelId,
                time: this.info.date + ' ' + this.info.time,
              },
            })
          } else {
            this.$toast({ type: 'error', message: res.msg })
          }
        })
      } else {
        // 创建
        createReserve(this.info).then(res => {
          if (res.code === 0) {
            this.$router.push({
              path: '/schedule-an-interview/success-reserve',
              query: {
                reserveId: this.info.reserveId,
                jobseekerChannelId: this.info.jobseekerChannelId,
                time: this.info.date + ' ' + this.info.time,
              },
            })
          } else {
            this.$toast({ type: 'error', message: res.msg })
          }
        })
      }
    },
    back() {
      const query = {
        reserveId: this.info.reserveId,
        jobseekerChannelId: this.info.jobseekerChannelId,
      }
      if (this.info.id) {
        query.id = this.info.id
      }
      this.$router.push({ path: '/schedule-an-interview', query })
    },
    getInterviewType(type) {
      const length = this.modleData.length
      for (let i = 0; i < length; i++) {
        if (parseInt(this.modleData[i].value) === parseInt(type)) {
          return this.modleData[i].name
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.confirm-reserve {
  background: url(../../assets/schedule-interview-header-bg.png) no-repeat;
  height: 100vh;
  background-color: #f5f5f5;
  background-size: 100%;
  .header {
    padding-top: 32px;
    padding-left: 30px;
    padding-bottom: 11px;
    .title,
    .subtitle {
      font-family: PingFangSC-Semibold, PingFang SC;
      color: #ffffff;
    }
    .title {
      height: 30px;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
    }
    .subtitle {
      margin-top: 7px;
      height: 21px;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .body {
    .card {
      margin: 0 20px;
      background: #ffffff;
      box-shadow: 0px 0px 4px 0px rgba(141, 141, 141, 0.5);
      border-radius: 10px;
      padding: 24px 22px 40px 22px;
      .title {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 25px;
      }
      .form {
        padding-top: 30px;
        .form-row + .form-row {
          padding-top: 18px;
        }
        .form-row {
          display: flex;
          .form-label,
          .form-content {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            line-height: 21px;
            font-weight: 400;
          }
          .form-label {
            min-width: 2.6rem;
            margin-right: 10px;
            color: #979797;
          }
          .form-content {
            color: #333333;
          }
        }
      }
    }
    .action {
      text-align: center;
      margin-top: 50px;
      span {
        width: 72px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 25px;
      }
      .submit {
        margin: 0 auto;
        width: 320px;
        height: 52px;
        background: #4f8fff;
        border-radius: 26px;
        padding: 13px;
        span {
          color: #ffffff;
        }
      }
      .cancel {
        margin-top: 16px;
        span {
          color: #4f8fff;
        }
      }
    }
  }
}
</style>
